import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = '/api/v1';
// axios.defaults.baseURL = 'http://localhost:7259/api/v1';
axios.interceptors.request.use(
  function (config){
    if(store.getters['isLoggedIn']){
      config.headers['Authorization'] = 'Bearer ' + store.state.SESSION_ID + ':' + store.state.SESSION_KEY;
    }
    return config;
  },
  null
);
axios.interceptors.response.use(
  null,
  function(err){

    if(err.response){
      // console.log(err.config)
      if(err.response.status == 401 && err.config.url != '/auth/check-credentials'){
        store.commit('unsetAuthData');
        store.dispatch('addMessage', {
          type: 'error',
          msg: 'Sesja wygasła'
        });
        router.push('/'); 
      }

    }

    return Promise.reject(err);
  }
)

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
