var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-page-inner"},[_c('v-sheet',{staticClass:"pa-8",attrs:{"rounded":"lg","color":"white"}},[_c('img',{staticClass:"login-page__logo",attrs:{"src":require("@/assets/imgs/pravna-icon.png"),"alt":"Ikona PRAVNA Group"}}),_c('h1',{staticClass:"text-h5 text-center font-weight-medium"},[_vm._v(" Lead-Scrapper ")]),_c('div',{staticClass:"text-body-1 text-center"},[_vm._v(" Logowanie ")]),_c('v-form',{ref:"form",staticClass:"mt-8",on:{"submit":function($event){$event.preventDefault();return _vm.onLogin.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"outlined":"","label":"Login","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            if(v.length > 63){
                                return 'Maksymalna długość pola to 63 znaki';
                            }    
                            return true;
                        }
                    ]},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('v-text-field',{attrs:{"type":"password","outlined":"","label":"Hasło","rules":[
                        function (v) {
                            if(!v || v == ''){
                                return 'To pole jest wymagane';
                            }
                            if(v.length > 63){
                                return 'Maksymalna długość pola to 63 znaki';
                            }    
                            return true;
                        }
                    ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","block":"","large":"","loading":_vm.loading},on:{"click":_vm.onLogin}},[_vm._v(" Zaloguj się ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }