import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login';
import Store from '../store';
import axios from 'axios';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Login
    },
    {
      path: '/pipedrive-api-key',
      name: 'PipedriveSetApiKey',
      component: () => import(/* webpackChunkName: "setup" */ '../views/PipedriveApiKey.vue')
    },
    {
        path: '/pipedrive-settings',
        name: 'PipedriveSettings',
        component: () => import(/* webpackChunkName: "setup" */ '../views/PipedriveSettings.vue')
    },
    {
        path: '/leads-source',
        name: 'LeadsSoruce',
        component: () => import(/* webpackChunkName: "setup" */ '../views/LeadsSource.vue')
    },
    {
        path: '/email-setup',
        name: 'EmailSetup',
        component: () => import(/* webpackChunkName: "setup" */ '../views/EmailSetup.vue')
    },
    {
        path: '/leads-filtering',
        name: 'LeadsFiltering',
        component: () => import(/* webpackChunkName: "leads" */ '../views/LeadsFiltering.vue')
    },
    {
        path: '/rejected-leads',
        name: 'RejectedLeads',
        component: () => import(/* webpackChunkName: "leads" */ '../views/RejectedLeads.vue')
    },
    {
        path: '/done',
        name: 'Done',
        component: () => import(/* webpackChunkName: "leads" */ '../views/Done.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {

    // 1. Jeżeli niezalogowano, a są dane w localStorage to przenosimy dane
    if(!Store.getters['isLoggedIn'] && window.localStorage){

        const SID = window.localStorage.getItem('LS_SID');
        const SK = window.localStorage.getItem('LS_SK'); 

        if(SID && SK){
            Store.commit('setAuthData', {
                session_id: SID,
                session_key: SK
            });
        }
    }

    // 2. jeżeli teraz są dane autoryzacyjne to sprawdzamy ich ważność
    if(Store.getters['isLoggedIn']){

        try{

            await axios.get("/auth/check-credentials");
            handleInitalLoadRouting(to, from, next);

            // próba pobrania z sessionStorage danych kolejnych kroków
            if(window.sessionStorage){

                const AK = window.sessionStorage.getItem('LS_PIPEDRIVE_AK');
                if(AK){
                    Store.state.PIPEDRIVE_API_KEY = AK;
                    Store.state.PIPEDRIVE_API_KEY_SET = true;
                }

                const PIPELINE = window.sessionStorage.getItem('LS_SELECTED_PIPELINE');
                const STAGE = window.sessionStorage.getItem('LS_SELECTED_STAGE');
                const DEAL_FIELDS = window.sessionStorage.getItem('LS_SELECTED_DEAL_FIELDS');
                if(PIPELINE && STAGE && DEAL_FIELDS){
                    
                    Store.state.PIPEDRIVE_SELECTED_PIPELINE = PIPELINE;
                    Store.state.PIPEDRIVE_SELECTED_STAGE = STAGE;
                    Store.state.PIPEDRIVE_SELECTED_DEAL_FIELDS = JSON.parse(DEAL_FIELDS);
                    Store.state.PIPEDRIVE_SETTINGS_SET = true;

                }

                const LEADS = window.sessionStorage.getItem('LS_LEADS_UNFILTERED');
                if(LEADS && !Store.state.LEADS_UPLOADED){
                    Store.state.LEADS_UNFILTERED = JSON.parse(LEADS);
                }
            }

        }
        catch(err){ 
            console.error(err);
            Store.commit('unsetAuthData');
            handleInitalLoadRouting(to, from, next);
        }

    }
    // jak nie, to do lądowania na '/'
    else{
        handleInitalLoadRouting(to, from, next);
    }

});

function handleInitalLoadRouting(to, from, next){

    try{

        // 1. Jeżeli nie jest zalogowany
        if(!Store.getters['isLoggedIn']){
            if(to.path == '/') return next();
            else return next('/');
        }

        // 2. jeżeli nie ustawiono jeszcze klucza API do Pipedrive, to hop na tą stronę
        if(Store.state.PIPEDRIVE_API_KEY_SET != true){
            if(to.path == '/pipedrive-api-key') return next();
            else return next('/pipedrive-api-key'); 
        }

        // 3. jeżeli nie wybrano jeszcze pipeline, stage i innych to hop 
        if(Store.state.PIPEDRIVE_SETTINGS_SET != true){
            if(to.path == '/pipedrive-settings') return next();
            else return next('/pipedrive-settings');
        }

        // 3.5. jeżeli są leady nieprzefiltrowane w pamięci to od razu hop do kroku filtrującego
        if(Store.state.LEADS_UNFILTERED != null){
            if(to.path == '/leads-filtering') return next();
            else return next('/leads-filtering');
        }

        // 4. jeżeli nie wybrano trybu pracy to hop do ekranu wyboru
        if(Store.state.IMPORT_MODE === null && !Store.state.LEADS_UPLOADED){
            if(to.path == '/leads-source') return next();
            else return next('/leads-source');
        }

        // 5. jeżeli wybrano mode na email to jump do danych maila
        if(Store.state.IMPORT_MODE === 'email' && !Store.state.LEADS_UPLOADED){
            if(to.path == '/email-setup') return next();
            else return next('/email-setup');
        }
        else if(Store.state.IMPORT_MODE === 'file' && !Store.state.LEADS_UPLOADED){
            if(to.path == '/file-import') return next();
            else return next('/file-import');
        }

        // 6. jeżeli próbuje iść na done, ale są jeszcze leady do ręcznej weryfikacji to tam go posyła
        if(Store.state.LEADS_REJECTED.length > 0){
            if(to.path == '/rejected-leads') return next();
            else return next('/rejected-leads');
        }

        // fallbackiem jest /done
        if(to.path == '/done') return next();
        else return next('/done');

    }
    catch(err){
        console.log(err);
        return next(false);
    }

    console.log('i nie wiem co dalej')

}

export default router;
