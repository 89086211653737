<template>
    <v-app>
        <PeMessages />
        <div class="blue-grey lighten-5" style="min-height: 100%;">
            <transition name="fade" mode="out-in" appear>
                <router-view :key="$route.fullPath" />
            </transition>
        </div>
    </v-app>
</template>

<script>
import PeMessages from '@/components/PeMessages';

export default {
    name: 'App',

    components: {
        PeMessages
    },

    data(){
        return {

            APP_STAGE: ''

        }
    },

    created(){
        // console.log('aoo')
    }
};
</script>

<style lang="scss">
    @import '@/assets/scss/main.scss';
</style>
