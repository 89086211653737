import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {

        messages: [],
        
        SESSION_ID: null,
        SESSION_KEY: null,

        PIPEDRIVE_API_KEY_SET: false,
        PIPEDRIVE_API_KEY: null,


        PIPEDRIVE_SETTINGS_SET: false,

        PIPEDRIVE_SELECTED_PIPELINE: null,
        PIPEDRIVE_SELECTED_STAGE: null,
        PIPEDRIVE_SELECTED_DEAL_FIELDS: [],

        IMPORT_MODE: null,

        LEADS_UNFILTERED: null,
        LEADS_REJECTED: [],
        LEADS_ACCEPTED: [],

        LEADS_UPLOADED: false

    },
    getters: {
        isLoggedIn: state => {
            return state.SESSION_ID != null && state.SESSION_KEY != null;
        }
    },
    mutations: {
        setAuthData(state, payload){
            state.SESSION_ID = payload.session_id;
            state.SESSION_KEY = payload.session_key;

            if(window.localStorage){
                localStorage.setItem('LS_SID', payload.session_id);
                localStorage.setItem('LS_SK', payload.session_key);
            }
        },
        unsetAuthData(state){
            state.SESSION_ID = null;
            state.SESSION_KEY = null;

            state.PIPEDRIVE_API_KEY_SET = false;
            state.PIPEDRIVE_API_KEY = null;
            
            state.PIPEDRIVE_SETTINGS_SET = false;

            state.PIPEDRIVE_SELECTED_PIPELINE = null;
            state.PIPEDRIVE_SELECTED_STAGE = null;
            state.PIPEDRIVE_SELECTED_DEAL_FIELDS = [];

            state.IMPORT_MODE = null;

            state.LEADS_UNFILTERED = null;
            state.LEADS_REJECTED = [];
            state.LEADS_ACCEPTED = [];

            state.LEADS_UPLOADED = false;

            if(window.localStorage){
                localStorage.removeItem('LS_SID');
                localStorage.removeItem('LS_SK');
            }
            if(window.sessionStorage){
                sessionStorage.removeItem('LS_SELECTED_PIPELINE');
                sessionStorage.removeItem('LS_SELECTED_STAGE');
                sessionStorage.removeItem('LS_SELECTED_DEAL_FIELDS');
                sessionStorage.removeItem('LS_PIPEDRIVE_AK');
                sessionStorage.removeItem('LS_LEADS_UNFILTERED');
            }
        },

        addMessage(state, data){
            state.messages.push({
                type: data.type,
                msg: data.msg,
                use_html: data.use_html || false,
                id: data.id
            });
        },
        removeMessage(state, id){
            let index = state.messages.findIndex(i => i.id == id);
            if(index != -1) state.messages.splice(index, 1);
        }
    },
    actions: {

        addMessage({commit}, data){

            const id = 'message_' + Date.now() + "_" + Math.floor(Math.random() * 10000);
    
            commit('addMessage', {
                ...data,
                id
            });
            
            if(!data.duration || data.duration != -1){
                let dur = 3500;
                if(data.duration) dur = data.duration;
    
                setTimeout(() => {
                    commit('removeMessage', id);
                }, dur);
            }
        }
    },
    modules: {
    }
});
