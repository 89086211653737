<template>
    <div class="login-page">
        <div class="login-page-inner">
            <v-sheet rounded="lg" color="white" class="pa-8">
                <img src="@/assets/imgs/pravna-icon.png" alt="Ikona PRAVNA Group" class="login-page__logo">
                <h1 class="text-h5 text-center font-weight-medium">
                    Lead-Scrapper
                </h1>
                <div class="text-body-1 text-center">
                    Logowanie
                </div>

                <v-form ref="form" class="mt-8" @submit.prevent="onLogin">
                    <v-text-field 
                        outlined
                        v-model="login"
                        label="Login"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                if(v.length > 63){
                                    return 'Maksymalna długość pola to 63 znaki';
                                }    
                                return true;
                            }
                        ]"
                    />
                    <v-text-field 
                        type="password"
                        outlined
                        v-model="password"
                        label="Hasło"
                        :rules="[
                            v => {
                                if(!v || v == ''){
                                    return 'To pole jest wymagane';
                                }
                                if(v.length > 63){
                                    return 'Maksymalna długość pola to 63 znaki';
                                }    
                                return true;
                            }
                        ]"
                    />
                </v-form>

                <v-btn
                    color="primary"
                    class="mt-2"
                    block
                    large
                    @click="onLogin"
                    :loading="loading"
                >
                    Zaloguj się
                </v-btn>
            </v-sheet>
        </div>
    </div>
</template>

<script>
export default {

    data(){
        return {
            login: '',
            password: '',

            loading: false
        }
    },

    methods: {
        async onLogin(){
            if(this.loading || !this.$refs.form.validate(true)) return;
            this.loading = true;

            try{

                const r = await this.axios.post("/auth/login", {
                    login: this.login,
                    password: this.password
                });

                this.$store.commit('setAuthData', r.data.data);

                this.$store.dispatch('addMessage', {
                    type: 'success',
                    msg: 'Zalogowano pomyślnie'
                });

                this.$router.push("/pipedrive-api-key");

            }
            catch(err){
                console.error(err);
                this.$store.dispatch('addMessage', {
                    type: 'error',
                    msg: err.response.data.msg
                });
            }

            this.loading = false;
        }
    }

}
</script>